<!-- eslint-disable no-irregular-whitespace -->
<!--
* @des: 用户协议
* @date: 2023-08-07 22:12:08
* @version:  1.0
*
* Date                   Author               Description
* ------------------------------------------------------
* 2023-08-07             曹天笑               创建
-->

<template>
  <div class="rule-page">
    <div class="top">
      <div class="back" @click="back"><img src="../assets/images/back.svg" alt="" /></div>
      <div class="title">{{ $i18n.t("home_agreement") }}</div>
    </div>
    <div class="content">
      <!-- <div class="page-title">{{ $i18n.t("home_p_title") }}</div>
      <div class="page-edit-time">
        {{ $i18n.t("home_last_time") }} {{ $moment(handleTime(info.UpdateAt)).format("YYYY-MM-DD HH:mm:ss") }}
      </div> -->
      <div class="detail" v-html="info.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {
        UpdateAt: new Date(),
        content: "",
      },
    };
  },
  created() {
    this.getContent();
  },
  methods: {
    async getContent() {
      const res = await this.$http.getAgreement({ID: 2});
      if (res.code === 0) {
        this.info = res.data.repbAgreement;
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.rule-page {
  .top {
    background: #3a82f6;
    border-bottom-left-radius: 0.48rem;
    border-bottom-right-radius: 0.48rem;
    position: relative;
    padding: 0.6rem 0 0.6rem 0;
    .back {
      position: absolute;
      left: 0.3rem;
      img {
        width: 0.6rem;
        height: 0.6rem;
      }
    }
    .title {
      color: #fff;
      font-weight: bold;
      font-size: 0.48rem;
    }
  }
  .content {
    padding: 0.48rem 0.48rem;
    text-align: left;
    .page-title {
      font-size: 0.36rem;
      color: #333;
      line-height: 0.4rem;
      margin-bottom: 0.2rem;
    }
    .page-edit-time {
      color: #999;
      font-size: 0.28rem;
      line-height: 0.32rem;
      margin-bottom: 0.3rem;
    }
    .detail {
      text-align: justify;
      font-size: 0.28rem;
      line-height: 0.4rem;
      color: #999;
    }
  }
}
</style>
